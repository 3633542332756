<template>
  <transition name="fade">
    <div
      v-if="layout.menu.isShow"
      :class="[s.wrap, flag.isActive ? s.isActive : '']"
      @click.self="flag.isActive = !flag.isActive">
      <div
        :class="[s.hamburger_wrap, flag.isActive ? s.isActive : '']"
        @click="flag.isActive = !flag.isActive">
        <div :class="s.hamburger">
          <span/><span/><span/>
        </div>
        <div :class="s.hamburger_text">MENU</div>
      </div>
      <ul :class="[s.menu, flag.isActive ? s.isActive : '']">
        <li
          v-for="(item, i) in menuItems"
          :key="i">
          <router-link
            :to="item.link"
            @click="flag.isActive = false">{{ item.label }}</router-link>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'global-header',
  data() {
    return {
      flag: {
        // isActive: false,
        isActive: false,
      },
      menuItems: [
        {
          label: '桐生市を知る',
          link: '/about/',
        },
        {
          label: '移住支援・サポート体制',
          link: '/shien/',
        },
        {
          label: '店舗開業・起業のすすめ',
          link: '/kaigyou/',
        },
        {
          label: '桐生市空き家・空き地バンク',
          link: 'http://kiryu-akiya.jp',
        },
        {
          label: '働く',
          link: '/hataraku/',
        },
        {
          label: '住む',
          link: '/sumu/',
        },
        {
          label: '育てる',
          link: '/kosodate/',
        },
        {
          label: '移住者インタビュー',
          link: '/interview/',
        },
        {
          label: 'お問い合わせ',
          link: '/contact/',
        },
      ],
    };
  },
  computed: {
    ...mapState(['layout']),
  },
  methods: {
    logout() {
    },
  },
};
</script>

<style lang="scss" module="s">
$mgn: 24px;
.wrap {
  transition: all .5s;
  position: fixed;
  z-index: 120;
  top: 0;
  right: 0;
  &.isActive {
    width: 100%;
    height: 100%;
    background-color: rgba(#A0A0A0, .5);
  }
}
.hamburger {
  &_wrap {
    transition: all .3s;
    position: absolute;
    padding: 16px;
    background-color: var(--surface-white);
    cursor: pointer;
    z-index: 2;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
    top: $mgn;
    right: $mgn;
    border-radius: 12px;
    display: flex;
    align-items: center;

    &.isActive {
      box-shadow: none;
    }
  }
  &_text {
    margin-left: 14px;
    font-weight: 600;
    white-space: nowrap;
    color: var(--brand-gray);
  }
  span {
    display: block;
    width: 18px;
    height: 2px;
    background-color: var(--brand-gray);
    border-radius: 20px;
    &:not(:first-child) {
      margin-top: 5px;
    }
  }
}
.menu {
  transition: all .3s;
  position: absolute;
  padding: 220px 0 220px 75px;
  width: 560px;
  height: 100vh;
  overflow: scroll;
  top: 0;
  right: -560px;
  background: #FFFFFF;
  border-radius: 12px 0 0 12px;
  z-index: 1;

  &.isActive {
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
    right: 0;
  }

  > li {
    font-weight: 700;
    font-size: 20px;
    &:not(:first-child) {
      margin-top: 48px;
    }
    a {
      color: var(--label-primary);
      transition: all .3s;
      &:hover {
        opacity: .6;
      }
    }
  }
  @include smView {
    width: calc(100% - 56px);
    padding-left: 47px;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      margin: 0 2px;
      background: #ccc;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px; background: #666;
    }
  }
}
</style>
